<template>
  <div>
    <navigation ref="navigation" :isSmall="false" :showBack="false" />
    <main class="page landing-page">
      <hero />
      <about @setActiveMenu="setActiveMenu" />
      <features />
      <pricing />
      <contact-us />
      <Footer />
    </main>
  </div>
</template>

<script>
import Navigation from "../components/Navigation/Navigation.vue";
import Hero from "../components/Hero/Hero.vue";
import About from "../components/About/About.vue";
import Features from "../components/Features/Features.vue";
import Pricing from "../components/Pricing/Pricing.vue";
import ContactUs from "../components/ContactUs/ContactUs.vue";
import Footer from "../components/Footer/Footer.vue";

export default {
  name: "App",
  components: {
    Navigation,
    Hero,
    About,
    Features,
    Pricing,
    ContactUs,
    Footer,
  },
  methods: {
    setActiveMenu(menu) {
      this.$refs.navigation.scrollTo(menu);
    },
  },
};
</script>

<style></style>
