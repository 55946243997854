<template>
  <section id="features" class="clean-block features">
    <div class="container">
      <div class="block-heading">
        <h2 class="text-info">Features</h2>
        <p>
          Simple to use, easy to understand and to set-up. These are some of the
          key features:
        </p>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-5 feature-box">
          <i class="icon-list icon"></i>
          <h4>Unlimited Contests</h4>
          <p>
            Every election can have an unlimited number of contests or
            questions.
          </p>
        </div>
        <div class="col-md-5 feature-box">
          <i class="icon-check icon"></i>
          <h4>Unlimited Contest Options</h4>
          <p>
            Each contest/question can have an unlimited number of options voters
            can choose from.
          </p>
        </div>
        <div class="col-md-5 feature-box">
          <i class="icon-user icon"></i>
          <h4>Proxy votes</h4>
          <p>
            If a voter is unwilling or unable to vote themselves, they can
            nominate a proxy to vote on their behalf. A proxy can vote on behalf
            of an unlimited number of voters.
          </p>
        </div>
        <div class="col-md-5 feature-box">
          <i class="icon-refresh icon"></i>
          <h4>Real-time election results</h4>
          <p>
            View the election results in real time as votes are being cast.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Features",
};
</script>

<style lang="scss" scoped></style>
