<template>
  <section id="pricing" class="clean-block slider dark">
    <div class="container">
      <div class="block-heading">
        <h2 class="text-info">Pricing</h2>
        <p>
          Clear and affordable pricing options. Upgrade or downgrade without any hassle.
        </p>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-5 col-lg-4">
          <div class="clean-pricing-item">
            <div class="heading">
              <h3>LITE</h3>
            </div>
            <p></p>
            <div class="features">
              <h4><span class="feature">No. of voters:&nbsp;</span><span>25</span></h4>
              <h4><span class="feature">No. of elections:&nbsp;</span><span>3</span></h4>
              <h4><span class="feature">Result storage:&nbsp;</span><span>30 days</span></h4>
              <h4><span class="feature">Own Branding:&nbsp;</span><span>No</span></h4>
              <h4><span class="feature">Printable result sheet:&nbsp;</span><span>No</span></h4>
            </div>
            <div class="price">
              <h4>$9</h4>
            </div>
            <button
              class="btn btn-outline-primary btn-block"
              type="button"
              @click="
                register({
                  name: 'Lite',
                  id: '1be52cc1-57a9-405c-a4e3-2edb64db1810',
                  price: 9
                })
              "
            >
              BUY NOW
            </button>
          </div>
        </div>
        <div class="col-md-5 col-lg-4">
          <div class="clean-pricing-item">
            <div class="ribbon"><span>Best Value</span></div>
            <div class="heading">
              <h3>PLUS</h3>
            </div>
            <p></p>
            <div class="features">
              <h4><span class="feature">No. of voters:&nbsp;</span><span>55</span></h4>
              <h4><span class="feature">No. of elections:&nbsp;</span><span>10</span></h4>
              <h4><span class="feature">Result storage:&nbsp;</span><span>90 days</span></h4>
              <h4><span class="feature">Own Branding:&nbsp;</span><span>Yes</span></h4>
              <h4><span class="feature">Printable result sheet:&nbsp;</span><span>No</span></h4>
            </div>
            <div class="price">
              <h4>$25</h4>
            </div>
            <button
              class="btn btn-primary btn-block"
              type="button"
              @click="
                register({
                  name: 'Plus',
                  id: '2a0c8502-213c-4d04-98f5-8ad97876e8cd',
                  price: 25
                })
              "
            >
              BUY NOW
            </button>
          </div>
        </div>
        <div class="col-md-5 col-lg-4">
          <div class="clean-pricing-item">
            <div class="heading">
              <h3>PREMIUM</h3>
            </div>
            <p></p>
            <div class="features">
              <h4><span class="feature">No. of voters:&nbsp;</span><span>Unlimited</span></h4>
              <h4><span class="feature">No. of elections:&nbsp;</span><span>Unlimited</span></h4>
              <h4><span class="feature">Result storage:&nbsp;</span><span>1 Year</span></h4>
              <h4><span class="feature">Own Branding:&nbsp;</span><span>Yes</span></h4>
              <h4><span class="feature">Printable result sheet:&nbsp;</span><span>Yes</span></h4>
            </div>
            <div class="price">
              <h4>$45</h4>
            </div>
            <button
              class="btn btn-outline-primary btn-block"
              type="button"
              @click="
                register({
                  name: 'Premium',
                  id: '3f1909ec-fdcc-4c89-a26c-da9cad87d61e',
                  price: 45
                })
              "
            >
              BUY NOW
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Pricing",
  methods: {
    register(plan) {
      this.$router.push({
        name: "Register",
        params: {
          plan: plan
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
