<template>
  <section id="contactus" class="clean-block clean-form dark">
    <div class="container">
      <div class="block-heading">
        <h2 class="text-info">Contact Us</h2>
        <p>
          Do you have a question you would like to ask us? Please get in touch using the form below.
        </p>
      </div>
      <form>
        <div class="alert alert-danger" v-if="errors.length > 0">
          The following error(s) occurred:
          <ul>
            <li v-for="error in errors" :key="error">
              {{ error.message }}
            </li>
          </ul>
        </div>
        <div class="form-group">
          <label>Name</label>
          <input v-model="name" class="form-control" :class="{ 'is-invalid': nameError }" type="text" id="name" />
          <div class="invalid-feedback">
            Please provide your full name.
          </div>
        </div>
        <div class="form-group">
          <label>Subject</label>
          <input
            v-model="subject"
            class="form-control"
            :class="{ 'is-invalid': subjectError }"
            type="text"
            id="subject"
          />
          <div class="invalid-feedback">
            Please provide a subject.
          </div>
        </div>
        <div class="form-group">
          <label>Email</label
          ><input v-model="email" class="form-control" :class="{ 'is-invalid': emailError }" type="email" id="email" />
          <div class="invalid-feedback">
            Please provide your email address.
          </div>
        </div>
        <div class="form-group">
          <label>Message</label>
          <textarea
            v-model="message"
            rows="5"
            class="form-control"
            :class="{ 'is-invalid': messageError }"
            id="message"
          ></textarea>
          <div class="invalid-feedback">
            Please provide a message.
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-12">
              <vue-recaptcha
                :loadRecaptchaScript="true"
                sitekey="6Ld2d-IZAAAAAK2BP-qlrT9fqn4EhlGaXSJpsCLS"
                @verify="captchaVerify"
                :class="{ 'is-invalid': captchaError }"
              ></vue-recaptcha>
              <div class="invalid-feedback">
                Captcha could not be validated.
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <button class="btn btn-primary btn-block" type="button" @click="submit" :disabled="submitting">
            <div class="spinner-border spinner-border-sm" role="status" v-if="submitting"></div>
            {{ buttonText }}
          </button>
        </div>
        <div class="form-group">
          <div class="alert alert-success" v-if="showSentMessage">
            Message has been sent. Thank you.
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
export default {
  name: "ContactUs",
  components: {
    VueRecaptcha
  },
  data() {
    return {
      buttonText: "Send",
      submitting: false,
      name: null,
      nameError: false,
      subject: null,
      subjectError: false,
      email: null,
      emailError: false,
      message: null,
      messageError: false,
      captchaError: null,
      captchaValid: false,
      errors: [],
      showSentMessage: false
    };
  },
  methods: {
    async submit() {
      this.errors = [];
      this.submitting = true;
      this.buttonText = "Sending, please wait...";
      if (this.checkForm()) {
        await this.sendEmail();
      }
      this.buttonText = "Send";
      this.submitting = false;
    },
    checkForm: function() {
      this.nameError = !this.name;
      this.subjectError = !this.subject;
      this.emailError = !this.email || !this.validEmail(this.email);
      this.messageError = !this.message;

      const captchaProblem = (this.captchaError != null && this.captchaError) || !this.captchaValid;
      this.captchaError = captchaProblem;

      return !this.nameError && !this.subjectError && !this.emailError && !this.messageError && !captchaProblem;
    },
    async captchaVerify(response) {
      const baseURI = "https://electo-api.azurewebsites.net/functions/verifyCaptcha";
      var result = await this.$axios.post(baseURI, {
        captchaResponse: response
      });
      this.captchaError = !result.data.validationPassed;
      this.captchaValid = result.data.validationPassed;
    },

    validEmail: function(email) {
      if (email.length > 0) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      }
      return false;
    },
    async sendEmail() {
      let config = {
        headers: {
          "Content-Type": "application/json"
        }
      };

      const baseURI =
        "https://func-electo-emailing.azurewebsites.net/api/ElectoEmailing?code=ZaB0DdVuhsUvHaHeuKaAqCu9kJfYKmZcU1Onyrh/9KnKRoPfGKWhDg==";

      const message = {
        emailType: "ContactUs",
        parameters: [
          {
            key: "name",
            value: this.name
          },
          {
            key: "subject",
            value: this.subject
          },
          {
            key: "email",
            value: this.email
          },
          {
            key: "message",
            value: this.message
          }
        ]
      };
      await this.$axios.post(baseURI, message, config);
      this.showSentMessage = true;
      this.name = null;
      this.subject = null;
      this.email = null;
      this.message = null;
      setTimeout(() => (this.showSentMessage = false), 3000);
    }
  }
};
</script>

<style lang="scss" scoped></style>
