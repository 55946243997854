<template>
  <footer class="page-footer dark">
    <div class="footer-copyright">
      <p>
        © 2020 Coalition Software (Pty) Ltd |
        <router-link to="/terms">Terms of Use</router-link> |
        <router-link to="/privacy-policy">Privacy Policy</router-link>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="css" scoped>
.page-footer {
  background-color: #f6f6f6;
}
</style>
