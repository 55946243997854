<template>
  <section id="about" class="clean-block clean-info dark">
    <div class="container">
      <div class="block-heading">
        <h2 class="text-info">About Electo</h2>
        <p>
          Electo makes it easy to create elections quickly and is ideal for
          organisations that need to elect board members or make decisions that
          require voting.
        </p>
      </div>
      <div class="row align-items-center">
        <div class="col-md-6">
          <img class="img-rounded" src="assets/img/AboutImage.png" />
        </div>
        <div class="col-md-6">
          <h3>Not just for organisation boards...</h3>
          <div class="getting-started-info">
            <p>
              It can also be used by any organisation to organise informal
              opinion polls. With the ability to nominate proxies, voters are in
              control of whether they want to vote personally or nominate
              someone to vote on their behalf.
            </p>
          </div>
          <button
            class="btn btn-outline-primary btn-lg"
            type="button"
            @click="scrollToPricing"
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
var VueScrollTo = require("vue-scrollto");

var options = {
  easing: "ease-in",
  offset: -60,
  force: true,
  cancelable: true,
  x: false,
  y: true,
};

export default {
  name: "About",
  methods: {
    scrollToPricing() {
      VueScrollTo.scrollTo("#pricing", 500, options);
      this.$emit("setActiveMenu", "#pricing");
    },
  },
};
</script>

<style lang="scss" scoped></style>
