<template>
  <div>
    <navigation ref="navigation" :isSmall="true" :showBack="true" />
    <main class="page blog-post" id="privacyPolicy">
      <section class="clean-block clean-post dark">
        <div class="container">
          <div class="block-content">
            <div class="post-body fourOhfour">
              <h1>Oops!</h1>
              <div class="post-info">
                <div class="row">
                  <div class="col-6">
                    <h4>
                      Something went wrong. We can't seem to find the page
                      you're looking for.
                    </h4>
                    <br />
                    <br />
                    <router-link
                      to="/"
                      role="button"
                      class="btn btn-primary btn-lg"
                    >
                      Go to Home page</router-link
                    >
                  </div>
                  <div class="col-6">
                    <img src="assets/img/404.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <Footer />
  </div>
</template>

<script>
import Navigation from "../components/Navigation/Navigation.vue";
import Footer from "../components/Footer/Footer.vue";
export default {
  name: "NotFound",
  components: {
    Navigation,
    Footer,
  },
};
</script>

<style lang="css" scoped>
.fourOhfour {
  min-height: 500px;
}
</style>
