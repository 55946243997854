<template>
  <section
    id="hero"
    class="clean-block clean-hero"
    style='background-image:url("assets/img/tech/image4.jpg");color:rgba(9, 162, 255, 0.85);'
  >
    <div class="text">
      <!-- <div
        class="text-nowrap d-flex justify-content-center align-items-center animated-text noSelect"
        style="background: rgba(255,255,255,0);border-style: none;margin-top: 16px;width: 50%;margin-right: auto;margin-left: auto;"
      >
        <div class="caption v-middle text-center">
          <h1 class="cd-headline clip">
            <span class="blc mr-3">Online voting that is </span>
            <span class="cd-words-wrapper">
              <b class="is-visible">simple.</b>
              <b>quick.</b>
              <b>easy.</b>
            </span>
            <span class="blinking">|</span>
          </h1>
        </div>
      </div> -->

      <div class="d-flex justify-content-center">
        <vue-typed-js
          :strings="['simple.', 'quick.', 'easy.']"
          :loop="true"
          :typeSpeed="120"
          :backSpeed="70"
          :backDelay="590"
        >
          <h1>Online voting that is <span class="typing"></span></h1>
        </vue-typed-js>
      </div>

      <div>
        <p>
          A handy to use solution for organisational elections, opinion polls
          and informal voting.
        </p>
        <button
          class="btn btn-outline-light btn-lg"
          type="button"
          @click.prevent="scrollTo('#about')"
        >
          Learn More
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { VueTypedJs } from "vue-typed-js";
var VueScrollTo = require("vue-scrollto");

var options = {
  easing: "ease-in",
  offset: -60,
  force: true,
  cancelable: true,
  x: false,
  y: true,
};

export default {
  name: "Hero",
  components: { VueTypedJs },
  methods: {
    scrollTo(element) {
      this.activeMenu = element;
      VueScrollTo.scrollTo(element, 500, options);
    },
  },
};
</script>

<style lang="scss" scoped></style>
