<template>
  <div>
    <navigation ref="navigation" :isSmall="true" :showBack="true" />
    <main class="page registration-page" id="register">
      <section class="clean-block clean-form dark">
        <div class="container">
          <div class="block-heading">
            <h2 class="text-info">Register</h2>
            <p v-if="this.$route.params.plan">
              Create your own elections with the
              <strong> {{ this.$route.params.plan.name }}</strong> plan.
            </p>
            <p v-else>
              Create your own elections.
            </p>
          </div>
          <div class="alert alert-danger" v-if="errors.length > 0">
            The following error(s) occurred:
            <ul>
              <li v-for="error in errors" :key="error">
                {{ error.message }}
              </li>
            </ul>
          </div>
          <form>
            <div class="form-group">
              <label for="name">Your Name</label
              ><input
                class="form-control item"
                :class="{ 'is-invalid': nameError }"
                type="text"
                placeholder="Your full name"
                v-model="name"
                id="fullname"
              />
              <div class="invalid-feedback">
                Please provide your full name
              </div>
            </div>
            <div class="form-group">
              <label for="name">Company Name</label
              ><input
                class="form-control item"
                :class="{ 'is-invalid': companyError }"
                type="text"
                placeholder="Your company name"
                v-model="companyName"
                id="companyname"
              />
              <div class="invalid-feedback">
                Please provide your company name
              </div>
            </div>
            <div class="form-group">
              <label for="email">Email</label
              ><input class="form-control item" :class="{ 'is-invalid': emailError }" type="email" v-model="email" />
              <div class="invalid-feedback">
                Please provide a valid email address.
              </div>
            </div>
            <div class="form-group">
              <label for="password">Password</label
              ><input
                class="form-control item"
                :class="{ 'is-invalid': passwordError }"
                type="password"
                v-model="password"
              />
              <div class="invalid-feedback">
                Please provide a password
              </div>
            </div>
            <div class="form-group">
              <label for="inviteCode">Invitation Code</label
              ><input
                class="form-control item"
                :class="{ 'is-invalid': inviteCodeError }"
                type="number"
                v-model="inviteCode"
              />
              <div class="invalid-feedback">
                Please provide a valid invitation code
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-12">
                  <vue-recaptcha
                    :loadRecaptchaScript="true"
                    sitekey="6Ld2d-IZAAAAAK2BP-qlrT9fqn4EhlGaXSJpsCLS"
                    @verify="captchaVerify"
                    :class="{ 'is-invalid': captchaError }"
                  ></vue-recaptcha>
                  <div class="invalid-feedback">
                    Captcha could not be validated.
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <small
                >By registering, you agree to our
                <router-link to="/terms" target="_blank">Terms of Use</router-link>.</small
              >
            </div>
            <button class="btn btn-primary btn-block" type="button" @click="submit" :disabled="registering">
              <div class="spinner-border spinner-border-sm" role="status" v-if="registering"></div>
              {{ buttonText }}
            </button>
          </form>
        </div>
      </section>
    </main>
    <Footer />
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import Navigation from "../components/Navigation/Navigation.vue";
import Footer from "../components/Footer/Footer.vue";
export default {
  name: "Register",
  components: {
    Navigation,
    Footer,
    VueRecaptcha
  },
  data() {
    return {
      registering: false,
      buttonText: "Sign Up",
      name: null,
      nameError: false,
      companyName: null,
      companyError: false,
      password: null,
      passwordError: null,
      email: null,
      emailError: false,
      users: null,
      errors: [],
      plan: null,
      inviteCode: null,
      inviteCodeError: null,
      captchaError: null,
      captchaValid: false
    };
  },
  methods: {
    async submit() {
      this.errors = [];
      this.registering = true;
      this.buttonText = "Registering, please wait...";
      if (this.checkForm()) {
        let config = {
          headers: {
            LandLord: "QUc4QkNuY21pakZPZE5Za1lQWVBFSnRjUUJ1QUhybzdzSFh0VjA="
          }
        };

        //const baseURI = "https://localhost:44320/tenants/register";
        const baseURI = "https://electo-api.azurewebsites.net/tenants/register";
        var result = await this.$axios.post(
          baseURI,
          {
            tenantName: this.companyName,
            ownerFullName: this.name,
            ownerEmail: this.email,
            ownerPassword: this.password,
            plan: this.$route.params.plan
          },
          config
        );

        this.errors = result.data.errors;
        this.buttonText = "Registered. Initiating payment...";
        if (this.errors && !this.errors.length > 0) {
          this.registering = false;
          this.checkOut();
        }
      }
      this.buttonText = "Sign Up";
      this.registering = false;
    },
    checkOut() {
      const Paddle = window.Paddle;
      Paddle.Setup({ vendor: 51106 });
      Paddle.Checkout.open({ product: 562360 });
    },
    checkForm: function() {
      this.nameError = !this.name;
      this.companyError = !this.companyName;
      this.passwordError = !this.password;
      this.emailError = !this.email || !this.validEmail(this.email);
      this.inviteCodeError = !this.inviteCode || this.inviteCode != 77777;

      const captchaProblem = (this.captchaError != null && this.captchaError) || !this.captchaValid;

      this.captchaError = captchaProblem;

      return (
        !this.nameError &&
        !this.companyError &&
        !this.passwordError &&
        !this.emailError &&
        !this.inviteCodeError &&
        !this.captchaError &&
        !captchaProblem
      );
    },
    validEmail: function(email) {
      if (email.length > 0) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      }
      return false;
    },
    async captchaVerify(response) {
      //const baseURI = "https://localhost:44320/functions/verifyCaptcha";
      const baseURI = "http://electo-api.azurewebsites.net/functions/verifyCaptcha";
      var result = await this.$axios.post(baseURI, {
        captchaResponse: response
      });
      this.captchaError = !result.data.validationPassed;
      this.captchaValid = result.data.validationPassed;
    }
  },
  mounted() {
    this.$refs.navigation.scrollTo("#register");
  },
  created() {
    if (this.$route.params.plan) {
      this.plan = this.$route.params.plan;
    } else {
      this.plan = {
        name: "Plus",
        id: "2a0c8502-213c-4d04-98f5-8ad97876e8cd",
        price: 35
      };
    }
  }
};
</script>

<style lang="css" scoped>
.block-heading {
  padding-top: 120px;
}
.invalid {
  border-width: 1px;
  border-color: red;
}
</style>
