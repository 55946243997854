<template>
  <div>
    <nav
      v-if="!isSmall"
      class="navbar navbar-light navbar-expand-lg navbar-expand-md bg-white clean-navbar"
    >
      <div class="container">
        <a class="navbar-brand logo" @click.prevent="scrollTo('#hero')">
          <img src="assets/img/logo.png" class="headerlogo"
        /></a>
        <button
          data-toggle="collapse"
          class="navbar-toggler"
          data-target="#navcol-1"
        >
          <span class="sr-only">Toggle navigation</span
          ><span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navcol-1">
          <ul class="nav navbar-nav ml-auto">
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: activeMenu === '#hero' }"
                @click.prevent="scrollTo('#hero')"
                >Home</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: activeMenu === '#about' }"
                @click.prevent="scrollTo('#about')"
                >About</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: activeMenu === '#features' }"
                @click.prevent="scrollTo('#features')"
                >Features</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: activeMenu === '#pricing' }"
                @click.prevent="scrollTo('#pricing')"
                >Pricing</a
              >
            </li>

            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: activeMenu === '#contactus' }"
                @click.prevent="scrollTo('#contactus')"
                >Contact Us</a
              >
            </li>

            <li class="nav-item">
              <a class="nav-link" href="https://hq.electo.app">Login</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <nav
      v-else
      class="navbar navbar-light navbar-expand-lg fixed-top bg-white clean-navbar"
    >
      <div class="container">
        <a class="navbar-brand logo">
          <img src="assets/img/logo.png" class="headerlogo"
        /></a>
        <div v-if="showBack">
          <button
            data-toggle="collapse"
            class="navbar-toggler"
            data-target="#navcol-1"
          >
            <span class="sr-only">Toggle navigation</span
            ><span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navcol-1">
            <ul class="nav navbar-nav ml-auto">
              <li class="nav-item">
                <router-link to="/" class="nav-link">Back to Home</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
var VueScrollTo = require("vue-scrollto");

var options = {
  easing: "ease-in",
  offset: -60,
  force: true,
  cancelable: true,
  x: false,
  y: true,
};

export default {
  name: "Navigation",
  props: ["isSmall", "showBack"],
  data() {
    return {
      activeMenu: "#hero",
    };
  },
  methods: {
    scrollTo(element) {
      this.activeMenu = element;
      VueScrollTo.scrollTo(element, 500, options);
    },
  },
};
</script>

<style lang="css" scoped>
.headerlogo {
  height: 45px;
}
.nav-link {
  cursor: pointer;
}
.navbar-brand {
  cursor: pointer;
}
</style>
